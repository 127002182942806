import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link as ScrollLink } from "react-scroll";

function Header() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleMenuClick = () => {
    setOpen(!open);
  };

  const renderMobileMenu = () => (
    <Drawer anchor="left" open={open} onClose={handleMenuClick}>
      <List>
        <ListItem button>
          <ListItemText>
            <ScrollLink  to="disclaimer" smooth={true} duration={400} style={{color: "white!important"}}>
              Disclaimer
            </ScrollLink>
          </ListItemText>{" "}
        </ListItem>
        <ListItem button>
          <ListItemText>
            <ScrollLink to="contacts" smooth={true} duration={400}  style={{color: "white!important"}}>
              Contacts
            </ScrollLink>
          </ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemText>
            <ScrollLink to="about" smooth={true} duration={400}  style={{color: "white!important"}}>
              About us
            </ScrollLink>
          </ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );

  return (
    <div>
      <AppBar
        position="static"
        sx={{ padding: 12}} className="header"
      >
        <Toolbar>
          <Box className="logo" sx={{ flexGrow: 1 }}>
            <Link to="/" style={{ textDecoration: "none", color: "primary.main" }}>
              <Typography>chipplaybet</Typography>
            </Link>
          </Box>
          {isMobile ? (
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuClick}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div>
              <Button color="inherit">
              <ScrollLink to="disclaimer" smooth={true} duration={400}>
              Disclaimer
                </ScrollLink>
              </Button>
              <Button color="inherit">
                <ScrollLink to="about" smooth={true} duration={400}>
                  About Us
                </ScrollLink>
              </Button>
              <Button color="inherit">
              <ScrollLink to="contacts" smooth={true} duration={400}>
                Contacts
                </ScrollLink>
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {isMobile && renderMobileMenu()}

      {/* Render the DisclaimerModal */}
    </div>
  );
}

export default Header;
