import React from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container"; // Import the Container component
import {games} from "../data/games.js"; // Adjust the path to match your project structure

function GameList() {
  return (
    <div style={{ borderTop: ".1px solid #1A4D2E" }}>
      <Container maxWidth="lg">
        <Typography variant="h3" textAlign="center" color="#ffffff">
          Our games
        </Typography>
        <Typography
          variant="h6"
          fontStyle="italic"
          marginBottom="1.5rem"
          textAlign="center"
          color="#ffffff"
        >
          Click on the game to start playing!
        </Typography>
        <Grid container spacing={4}>
          {games.map((game) => (
            <Grid item sm={6} md={4} key={game.id}>
              <Card>
                <CardActionArea component={Link} to={`/game/${game.id}`}>
                  <CardMedia
                    component="img"
                    alt={game.title}
                    height="180"
                    image={game.image}
                    title={game.title}
                    style={{ fontSize: "14px" }}
                  />
                  <CardHeader
                    title={game.title}
                    sx={{
                      color: "#ffffff",
                      textAlign: "center",
                      padding: "1rem 0",
                      backgroundColor: "primary.dark"
                    }}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}

export default GameList;
