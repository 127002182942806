import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Grow from "@mui/material/Grow";

const useStyles = makeStyles((theme) => ({
  container: {
    color: "#ffffff",
  },
  paperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    textAlign: "center",
    color: "#ffffff",
  },
  paper: {
    padding: "2rem 1.5rem",
    borderRadius: "30px!important",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white!important",
  },
}));

export default function Benefits() {
  const classes = useStyles(); // Apply the defined styles

  return (
    <Container maxWidth="lg" className={classes.container} id="about">
      <Typography variant="h4" gutterBottom textAlign="center">
        Welcome to chipplaybet, where every game is an opportunity for pure joy and entertainment. Here, we champion the simple joys of gaming, offering a platform free from the pressures of stakes or rewards—just uninterrupted fun.
      </Typography>
      <Typography variant="h6" gutterBottom marginTop="1rem">
        Our dedication to innovation and user experience has led us to create a haven where technology meets creativity. Explore our wide-ranging genres of games, each curated to cater to both the thrill-seeker and the peace-seeker alike.
      </Typography>
      <Typography variant="h6" gutterBottom>
        At chipplaybet, we redefine gaming culture by focusing solely on the pleasure of play. It's not about competition or prizes here; it’s about unwinding, connecting with others, and enjoying games in their most authentic form.
      </Typography>
      <Typography variant="h4" gutterBottom textAlign="center" marginTop="2rem">
        Discover the chipplaybet Difference
      </Typography>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        spacing={3}
        marginTop="1rem"
      >
        <Grow in={true} timeout={1000} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper} sx={{backgroundColor: "primary.dark"}}>
              <Typography variant="h5">Endless Variety</Typography>
              <Typography variant="body1">
                Dive into our expansive library of games, with new adventures added regularly to keep your experiences fresh and thrilling.
              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1400} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper} sx={{backgroundColor: "primary.dark"}}>
              <Typography variant="h5">User-Focused Design</Typography>
              <Typography variant="body1">
                Navigate effortlessly through our site with a platform designed from the ground up to be accessible, intuitive, and user-friendly.
              </Typography>
            </Paper>
          </Grid>
        </Grow>

        <Grow in={true} timeout={1600} appear={true}>
          <Grid item xs={12} sm={4} className={classes.paperContainer}>
            <Paper className={classes.paper} sx={{backgroundColor: "primary.dark"}}>
              <Typography variant="h5">Completely Free</Typography>
              <Typography variant="body1">
                Step into a world where fun doesn’t cost a thing. No fees, no subscriptions—just free, unbridled play anytime, anywhere.
              </Typography>
            </Paper>
          </Grid>
        </Grow>
      </Grid>
    </Container>
  );
}
