import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      dark: '#272838',    // Dark violet-gray
      main: '#7E7F9A',    // Muted violet-gray
      main600: '#72747E', // Slightly darker shade of main
      main500: '#6A6C78', // Another slightly darker shade
      main400: '#7E7F9A', // Your main color
      main300: '#9495AD', // Lighter than main
      main200: '#A9AAC0', // Even lighter
      main100: '#BEBFCF', // Near light shade
      main50: '#D3D4DF',  // Very light shade
    },
    secondary: {
      main: '#F3DE8A',    // Warm yellow for primary accent
      secondary: '#EB9486' // Soft red for secondary accent
    },
  },
  typography: {
    fontFamily: 'Rowdies, sans-serif', 
    color: "white",
    h3: {
      fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '3rem',
    },
    },
    h4: {
      fontSize: '1.2rem',
    '@media (min-width:600px)': {
      fontSize: '2rem',
    },
  }
  },
  spacing: [0, 4, 8, 16, 32, 64],
});

export default theme;
