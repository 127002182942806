import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import {bg} from "../assets";
import {MdNoAdultContent} from "react-icons/md";

function Copyright() {
  return (
    <Typography variant="body2" >
      {'Copyright © '}
      chipplaybet {new Date().getFullYear()}{'.'}
    </Typography>
  );
}


export default function StickyFooter() {
  return (
      <Box
        component="footer"
        sx={{
          px: 2,
          mt: 'auto',           
          backgroundColor: "primary.dark", color: "#ffffff"
        }}
      >
        <Container maxWidth="sm" style={{paddingTop: "1.5rem", paddingBottom: "1.5rem", display: "flex", justifyContent: "center", alignItems: "end", textAlign: "center"}}>
          <div>
          <Typography variant="body1">
            <Link to="/terms"  style={{color: "#ffffff", textDecoration: "none"}}>
              Terms
            </Link>{' '}
            |{' '}
            <Link to="/privacy"  style={{color: "#ffffff", textDecoration: "none"}}>
              Privacy Policy
            </Link>
          </Typography>
          <Copyright />
          <Typography variant="body1" textTransform="uppercase">
            Does not involve the use of Real Money. <br/>
            Practicing casino gaming for fun does not imply future success in real money gambling. <br/>
            This website is intended for persons over 18 years of age and is for entertainment purposes only.   
            </Typography>
          </div>
        </Container>
      </Box>
  );
}
