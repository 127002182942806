import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const DisclaimerSection = () => {
  return (
    <div className="disclaimer">
      <Container>
      <Typography variant="h3">Disclaimer</Typography>
      <Typography variant="body1" paragraph>
      The games provided by chipplaybet are intended for use by those 18 or older for amusement purposes only. <br/> The games do not offer "real money gambling" or an opportunity to win real money or prizes. Practice or success at social casino gaming does not imply future success at real money gambling. Our platform operates in compliance with US laws and regulations regarding free online games. Use of this platform is strictly for entertainment and does not involve the use of real money.
  <br/>We are committed to promoting responsible gaming and encourage our users to play responsibly. Please enjoy our Services in a responsible manner.
    </Typography>
     
      </Container>
    </div>
  );
};

export default DisclaimerSection;
