// src/components/Hero.js
import React from "react";
import Header from "./Header"; // Import your Header component
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { heroBg, bg } from "../assets";
import Grow from "@mui/material/Grow";

function Hero() {
  return (
    <Box
      height="80vh"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      style={{
        background: `linear-gradient(161deg, rgba(39,40,56,.9) 34%, rgba(126,127,154,.9) 55%, rgba(39,40,56,.9) 97%), url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Header />
      <Container maxWidth="lg">
        <Grow in={true} timeout={1200}>
          <Box px={3} textAlign="center">
            <Typography variant="h3" sx={{color: "secondary.secondary"}}>
              Welcome to chipplaybet
            </Typography>
            <Typography
              variant="h1"
              fontSize={{ xs: "4rem", md: "6rem" }}
              gutterBottom
              sx={{ fontWeight: "900", color: "secondary.main" }}
            >
              Play free games online
            </Typography>
            <Typography variant="body1" sx={{color: "white"}}>
            Does not involve the use of Real Money. <br/>
            Practicing casino gaming for fun does not imply future success in real money gambling. <br/>
            This website is intended for persons over 18 years of age and is for entertainment purposes only.   
          
            </Typography>
          </Box>
        </Grow>
      </Container>
    </Box>
  );
}

export default Hero;
