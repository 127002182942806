import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { MailOutline } from "@mui/icons-material";
import Form from "./Form";

export default function Contacts() {
  return (
    <Box sx={{ backgroundColor: "primary.dark" }}>
      <Container maxWidth="lg" id="contacts" margin="2rem auto">
      <Box color="white" textAlign="center">
        <Typography variant="h3">Contact information</Typography>
        <Box sx={{display: "flex", flexDirection: "column", justifyContent: "center", margin: "1rem auto 3rem"}}>
        <Typography variant="h6">+18189209393</Typography>
        <Typography variant="h6">16700 Schoenborn St #2, North Hills, CA 91343, United States</Typography>
        <Typography variant="h6">contacts@chipplaybet.com</Typography>

        </Box>
      </Box>

        <Typography variant="h3" textAlign="center" color="white" gutterBottom>
          Have Any Question?
        </Typography>
        <Box
          sx={{
            maxWidth: "900px",
            width: "100%",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" margin="1rem auto" color="white">
             Feel free to reach out to us
            and we'll be happy to help!
          </Typography>
          <Form/>
        </Box>
      </Container>
    </Box>
  );
}
